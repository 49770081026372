a.meanmenu-reveal {
  display: none;
}

.mean-container .mean-bar {
  float: left;
  z-index: 999999;
  background: none;
  width: 100%;
  min-height: 42px;
  padding: 4px 0 0;
  position: relative;
}

.mean-container a.meanmenu-reveal {
  cursor: pointer;
  color: #fff;
  text-indent: -9999em;
  float: right;
  width: 22px;
  height: 22px;
  padding: 13px 13px 11px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1px;
  font-weight: 700;
  line-height: 22px;
  text-decoration: none;
  display: block;
  top: 0;
  right: 0;
}

.mean-container a.meanmenu-reveal span {
  background: #fff;
  height: 3px;
  margin-top: 3px;
  display: block;
}

.mean-container .mean-nav {
  float: left;
  background: #0c1923;
  width: 100%;
}

.mean-container .mean-nav ul {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.mean-container .mean-nav ul li {
  float: left;
  background: #f8f8f8;
  border-top: 1px solid #ddd;
  width: 100%;
  position: relative;
}

.mean-container .mean-nav ul li a {
  color: #666;
  float: left;
  text-align: left;
  text-transform: uppercase;
  background: #f8f8f8;
  width: 90%;
  margin: 0;
  padding: 1em 5%;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  display: block;
}

.mobile-menu-area {
  background: #262626;
}

.mean-container .mean-nav ul li li a {
  opacity: .75;
  visibility: visible;
  text-transform: capitalize;
  color: #444;
  border-top: 1px solid #ffffff40;
  width: 80%;
  padding: 1em 10%;
  font-weight: normal;
  text-shadow: none !important;
}

.mean-container .mean-nav ul li.mean-last a {
  border-bottom: 0;
  margin-bottom: 0;
}

.mean-container .mean-nav ul li li li a {
  width: 70%;
  padding: 1em 15%;
}

.mean-container .mean-nav ul li li li li a {
  width: 60%;
  padding: 1em 20%;
}

.mean-container .mean-nav ul li li li li li a {
  width: 50%;
  padding: 1em 25%;
}

.mean-container .mean-nav ul li a:hover {
  color: #50d7de;
  background: #f8f8f8;
}

.mean-container .mean-nav ul li a.mean-expand {
  text-align: center;
  z-index: 2;
  background: #f8f8f8;
  width: 26px;
  height: 15px;
  margin-top: 1px;
  font-weight: 700;
  position: absolute;
  top: 0;
  right: 0;
  border: 0 !important;
  padding: 12px !important;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  background: #f8f8f8;
}

.mean-container .mean-push {
  float: left;
  clear: both;
  width: 100%;
  margin: 0;
  padding: 0;
}

.mean-nav .wrapper {
  width: 100%;
  margin: 0;
  padding: 0;
}

.mean-container .mean-bar, .mean-container .mean-bar * {
  box-sizing: content-box;
}

.mean-remove {
  display: none !important;
}

.mean-container .mean-bar:after {
  color: #fff;
  content: ".";
  text-indent: -999px;
  background: url("company_logo.68cc081f.png") no-repeat;
  width: 180px;
  height: 45px;
  font-size: 21px;
  position: absolute;
  top: 5px;
  left: 5%;
}
/*# sourceMappingURL=index.1932e225.css.map */
